import PropTypes from 'prop-types'
import React from 'react'
import { buttonLinkTypeValues } from 'common/constants/ButtonLinkTypes'
import { borderTypeValues } from 'common/constants/settings'
import { addUrlSchemaToTheLink } from 'common/utils/urlUtils'
import Button from './ui/Button'
import SubTextUi from './ui/SubTextUi'
import ButtonContentUi from './ui/button-content-ui'
import ButtonIconUi from './ui/button-icon-ui'

function Link({
  styles,
  mobileStyles,
  borderType,
  mobileBorderType,
  attrId,
  dataTestId,
  href,
  children,
  rel,
  target,
  subText,
  subTextStyles,
  subTextMobileStyles,
  iconClassNameBefore,
  iconClassNameAfter,
  hoverStyles,
  hoverMobileStyles,
}) {
  return (
    <Button
      dataTestId={dataTestId}
      id={attrId}
      href={addUrlSchemaToTheLink(href)}
      as="a"
      target={target}
      rel={rel}
      styles={{ ...styles, ...borderTypeValues[borderType] }}
      mobileStyles={{ ...mobileStyles, ...borderTypeValues[mobileBorderType] }}
      hoverStyles={hoverStyles}
      hoverMobileStyles={hoverMobileStyles}
    >
      <ButtonContentUi>
        {iconClassNameBefore && (
          <ButtonIconUi className={iconClassNameBefore} placed={'left'} />
        )}
        {children}
        {iconClassNameAfter && (
          <ButtonIconUi className={iconClassNameAfter} placed={'right'} />
        )}
      </ButtonContentUi>
      {subText && (
        <SubTextUi styles={subTextStyles} mobileStyles={subTextMobileStyles}>
          {subText}
        </SubTextUi>
      )}
    </Button>
  )
}

Link.propTypes = {
  attrId: PropTypes.string,
  borderType: PropTypes.string.isRequired,
  mobileBorderType: PropTypes.string,
  children: PropTypes.any.isRequired,
  dataTestId: PropTypes.string,
  href: PropTypes.string,
  mobileStyles: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  ).isRequired,
  styles: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  ).isRequired,
  target: PropTypes.oneOf(Object.values(buttonLinkTypeValues)),
  rel: PropTypes.string,
  subText: PropTypes.string,
  subTextStyles: PropTypes.shape({
    color: PropTypes.string,
    fontSize: PropTypes.string,
    fontFamily: PropTypes.string,
  }),
  subTextMobileStyles: PropTypes.shape({
    color: PropTypes.string,
    fontSize: PropTypes.string,
    fontFamily: PropTypes.string,
  }),
}

Link.defaultProps = {
  href: '',
  rel: '',
}

export default Link
