import { useRollbar } from '@rollbar/react'
import React from 'react'
import { CommonRemotePopupBody } from 'common/components/entities/remote-popup-body'
import { RemotePopupBodyInterface } from 'common/types/remote-popup-body-interface'
import { closeRemotePopup } from 'publisher/actionsManagement'
import BaseNodeChildren from 'publisher/components/ChildrenEntities'
import { useBackgroundImage } from 'publisher/hooks/useBackgroundImage'
import { usePage } from 'publisher/store'
import pageSelectors from 'publisher/store/page/pageSelectors'
import { useAppDispatch } from 'client/hooks/use-dispatch'

export interface RemotePopupBodyProps {
  entity: RemotePopupBodyInterface
}

const RemotePopupBody = ({ entity }: RemotePopupBodyProps) => {
  const rollbar = useRollbar()
  const dispatch = useAppDispatch()

  const onCloseHandler = (popupId: string, closedDays?: number) => {
    try {
      dispatch(closeRemotePopup(popupId, closedDays))
    } catch (e) {
      rollbar.error('Popup close failed', e as Error)
    }
  }

  const fontFamily = usePage(pageSelectors.getGlobalTextFontFamily)
  const fontSize = usePage(pageSelectors.getGlobalTextFontSize)
  const mobileFontSize = usePage(pageSelectors.getGlobalMobileTextFontSize)

  const { backgroundImage, mobileBackgroundImage } = useBackgroundImage({
    backgroundFileId: entity.backgroundFileId,
    mobileBackgroundFileId: entity.mobileBackgroundFileId,
  })

  return (
    <CommonRemotePopupBody
      onClose={() => onCloseHandler(entity.id, entity.closedDays)}
      closeButtonColor={entity.closeButtonColor}
      mobileCloseButtonColor={entity.mobileCloseButtonColor}
      background={entity.background}
      mobileBackground={entity.mobileBackground}
      backgroundImage={backgroundImage}
      mobileBackgroundImage={mobileBackgroundImage}
      backgroundColor={entity.backgroundColor}
      mobileBackgroundColor={entity.mobileBackgroundColor}
      border={entity.border}
      mobileBorder={entity.mobileBorder}
      boxShadow={entity.boxShadow}
      mobileBoxShadow={entity.mobileBoxShadow}
      padding={entity.padding}
      mobilePadding={entity.mobilePadding}
      showClose={entity.showClose}
      fontFamily={fontFamily}
      fontSize={fontSize}
      mobileFontSize={mobileFontSize}
      overflow={'visible'}
      maxHeight={'calc(100vh - 40px)'}
    >
      <BaseNodeChildren childIds={entity.childIds} />
    </CommonRemotePopupBody>
  )
}

export default RemotePopupBody
